import { useToken } from './useToken'

export const useArchive = () => {
  const { getToken } = useToken()

  const archive = async (id, type: 'insurance' | 'address') => {
    let body
    if (type === 'insurance') {
      body = {
        insuranceId: id,
        type: 'insurance'
      }
    } else if (type === 'address') {
      body = {
        addressId: id,
        type: 'address'
      }
    }
    try {
      const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/archive`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getToken()}`
        },
        body: JSON.stringify(body)
      })

      return await res.json()
    } catch (err) {
      return err
    }
  }

  return {
    archive
  }
}
