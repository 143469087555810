import { gql, useQuery } from '@apollo/client'

const DRUGS = gql`
  query Drugs($query: String, $language: String, $page: Int, $perPage: Int) {
    drugs(query: $query, language: $language, page: $page, per_page: $perPage) {
      page
      total_count
      total_pages
      drugDetails {
        form
        strength
        drugId
        GENcode
        legalStatusQc
        name
        drugIdentificationNumber
        genericInfo {
          name
        }
        formEnLowercase
        formFrLowercase
        nameEnCapitalized
        nameFrCapitalized
        defaultPosology
        strengthEnLowercase
        strengthFrLowercase
        videoUrl
      }
    }
  }
`

export interface IUseGetAllDrugsInfoProps {
  query: string
  page?: number
  perPage?: number
}

export const useGetAllDrugsInfo = ({ query, page = 1, perPage = 50 }: IUseGetAllDrugsInfoProps) =>
  useQuery(DRUGS, {
    variables: {
      query,
      page,
      perPage
    },
    fetchPolicy: 'network-only'
  })
