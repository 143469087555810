import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const EDIT_PHARMACY = gql`
  mutation EditPharmacy(
    $pharmacyId: Int!
    $name: String
    $address: String
    $city: String
    $province: String
    $postalCode: String
    $isActive: Boolean
    $phone: String
    $email: String
    $website: String
    $bannerName: String
    $bannerLogo: String
    $businessHours: JSON
    $empegoCode: String
  ) {
    editPharmacy(
      pharmacyId: $pharmacyId
      name: $name
      address: $address
      city: $city
      province: $province
      postalCode: $postalCode
      isActive: $isActive
      phone: $phone
      email: $email
      website: $website
      bannerName: $bannerName
      bannerLogo: $bannerLogo
      businessHours: $businessHours
      empegoCode: $empegoCode
    ) {
      pharmacyId
    }
  }
`

export const useEditPharmacy = () => {
  const t = useTranslation()
  const [editPharmacy, editPharmacyQuery] = useMutation(EDIT_PHARMACY)
  useErrorToast({ error: editPharmacyQuery?.error })
  useSuccessToast({
    success: !!editPharmacyQuery?.data,
    message: t('common.clientUpdatedSuccessfully')
  })
  return { editPharmacy, editPharmacyQuery }
}
