import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const TASKS_CATEGORIES = gql`
  query TaskCategories {
    taskCategories {
      id
      name
      nameFr
      description
      descriptionFr
      isDeleted
      type
    }
  }
`

export const useGetTasksCategories = (enabled: boolean = true) => {
  const taskQuery = useQuery(TASKS_CATEGORIES, {
    fetchPolicy: 'network-only',
    skip: !enabled
  })

  useErrorToast({ error: taskQuery?.error })

  return taskQuery
}
