import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const PRESET_MESSAGES = gql`
  query PresetMessages {
    presetMessages {
      id
      isDeleted
      messageEn
      messageFr
      titleEn
      titleFr
    }
  }
`

export const useGetPresetMessages = () => {
  const presetMessagesQuery = useQuery(PRESET_MESSAGES, {
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: presetMessagesQuery?.error })

  return presetMessagesQuery
}
