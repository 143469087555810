import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const ADD_ADDRESS = gql`
  mutation addAddress($addressInput: AddressInput!, $oAuthId: String) {
    addAddress(addressInput: $addressInput, oAuthId: $oAuthId) {
      addressId
    }
  }
`

export const useAddAddress = () => {
  const t = useTranslation()
  const [addAddress, addAddressQuery] = useMutation(ADD_ADDRESS)
  useErrorToast({ error: addAddressQuery?.error })
  useSuccessToast({
    success: !!addAddressQuery?.data,
    message: t('common.addressAddedSuccessfully')
  })
  return {
    addAddress,
    addAddressQuery
  }
}
