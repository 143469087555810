import { useEffect } from 'react'
import { useGetMeContext } from '@/contexts'
import { useRouter } from 'next/router'

export const useValidateUserAccess = () => {
  const router = useRouter()
  const { me, userLoading } = useGetMeContext()
  const isSuperAdminRoute = router.asPath.startsWith('/super-admin')
  const userNotAllowed = isSuperAdminRoute && !me?.isSuperAdmin

  useEffect(() => {
    if (!userLoading && Object.keys(me ?? {}).length && userNotAllowed) {
      router.replace('/tasks')
    }
  }, [userLoading, me])

  return { userLoading, isSuperAdminRoute, userNotAllowed }
}
