import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const UNLINK_PHARMACY_USER_WITH_USER = gql`
  mutation UnlinkPharmacyUserFromPharmacy($oAuthId: String!, $pharmacyId: Int!) {
    unlinkPharmacyUserFromPharmacy(oAuthId: $oAuthId, pharmacyId: $pharmacyId)
  }
`

export const useUnlinkPharmacyUserWithPharmacy = () => {
  const t = useTranslation()
  const [unlinkPharmacyUserWithPharmacy, unlinkPharmacyUserWithPharmacyQuery] = useMutation(UNLINK_PHARMACY_USER_WITH_USER)
  useErrorToast({ error: unlinkPharmacyUserWithPharmacyQuery?.error })
  useSuccessToast({
    success: !!unlinkPharmacyUserWithPharmacyQuery?.data,
    message: t('common.unlinkUserFromEstablishmentSuccessfully')
  })
  return { unlinkPharmacyUserWithPharmacy, unlinkPharmacyUserWithPharmacyQuery }
}
