import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const ADD_DRUG = gql`
  mutation AddDrug($drugInput: DrugInput!, $defaultPosology: String) {
    addDrug(drugInput: $drugInput, defaultPosology: $defaultPosology) {
      drugId
    }
  }
`

export const useAddDrug = () => {
  const t = useTranslation()
  const [addDrug, addDrugQuery] = useMutation(ADD_DRUG)
  useErrorToast({ error: addDrugQuery?.error })
  useSuccessToast({
    success: !!addDrugQuery?.data,
    message: t('common.drugCreated')
  })

  return { addDrug, addDrugQuery }
}
