import { gql, useQuery } from '@apollo/client'

const FETCH_DOCUMENTS = gql`
  query FetchDocuments($documentType: String) {
    fetchDocuments(documentType: $documentType) {
      id
      name
      pharmacyId
      uploadedBy
      documentType
      imageId
    }
  }
`

export interface IUseFetchDocumentsProps {
  documentType?: string
}

export const useFetchDocuments = (variables: IUseFetchDocumentsProps = {}) =>
  useQuery(FETCH_DOCUMENTS, {
    fetchPolicy: 'network-only',
    variables
  })
