import { gql, useQuery } from '@apollo/client'

const PATHOLOGY_INFO = gql`
  query getGenericInfo($pathologyId: Int) {
    getPathology(pathologyId: $pathologyId) {
      pathologyNameEn
    }
  }
`
export interface IUseGetPathologyInfoProps {
  pathologyId: number
}

export const useGetPathologyInfo = ({ pathologyId }: IUseGetPathologyInfoProps) =>
  useQuery(PATHOLOGY_INFO, {
    variables: {
      pathologyId
    },
    fetchPolicy: 'network-only'
  })
