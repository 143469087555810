import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const EDIT_USER = gql`
  mutation EditUser(
    $oAuthId: String!
    $logipharmId: String
    $domedicPatientId: String
    $email: String
    $phoneNumber: String
    $firstName: String
    $lastName: String
    $birthday: String
    $gender: Gender
    $language: String
    $ramqNumber: String
    $role: Role
    $stripeCustomerId: String
    $pharmacyId: Int
  ) {
    editUser(
      oAuthId: $oAuthId
      logipharmId: $logipharmId
      domedicPatientId: $domedicPatientId
      email: $email
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      birthday: $birthday
      gender: $gender
      language: $language
      ramqNumber: $ramqNumber
      role: $role
      stripeCustomerId: $stripeCustomerId
      pharmacyId: $pharmacyId
    )
  }
`

export const useEditClientUser = () => {
  const t = useTranslation()
  const [editUser, editUserQuery] = useMutation(EDIT_USER)
  useErrorToast({ error: editUserQuery?.error })
  useSuccessToast({
    success: !!editUserQuery?.data,
    message: t('common.clientUpdatedSuccessfully')
  })
  return { editUser, editUserQuery }
}
