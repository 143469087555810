import { gql, useQuery } from '@apollo/client'

const ADDRESS = gql`
  query getAddress($addressId: String) {
    getAddress(addressId: $addressId) {
      addressId
      oAuthId
      label
      streetAddressLineOne
      streetAddressLineTwo
      city
      province
      postalCode
      deactivated
      defaultAddress
      withinDeliveryRadius
    }
  }
`

export interface IUseGetAddressProps {
  addressId: string
}

export const useGetAddress = ({ addressId }: IUseGetAddressProps) =>
  useQuery(ADDRESS, {
    variables: {
      addressId
    },
    fetchPolicy: 'network-only'
  })
