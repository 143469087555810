import { createContext } from 'react'
import { IUserProps } from '@/utils/types'
import { noop } from 'lodash'

export interface IUserProfileContext {
  user: IUserProps | null
  refetchUser: () => void
  isLoading: boolean
  userId: string
  currentTab: number
}
export const UserProfileContext = createContext<IUserProfileContext>({
  user: null,
  refetchUser: noop,
  isLoading: false,
  userId: null,
  currentTab: 0
})
