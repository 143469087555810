import { useState } from 'react'
import { useErrorToast } from './useErrorToast'
import { useToken } from './useToken'

export const useUploadFiles = () => {
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)
  useErrorToast({ error: error, message: error })
  const { getToken } = useToken()

  const uploadFiles = async (files, type) => {
    setIsLoading(true)
    const data = new FormData()

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      data.append('image', file, `${file.name}___${type}`)
    }

    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/upload`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${await getToken()}`
      },
      body: data
    })
    const fetchRes = await res.json()

    setIsLoading(false)
    if (fetchRes.error) {
      setError(fetchRes.error)
      return []
    }

    setData(fetchRes.imageIds)
    return fetchRes.imageIds
  }
  return { uploadFiles, isLoading, error, data }
}
