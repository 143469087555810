import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast, useSuccessToast } from '.'

const REMOVE_DOCUMENT = gql`
  mutation RemoveDocument($removeDocumentId: Int!) {
    removeDocument(id: $removeDocumentId)
  }
`

export const useRemoveDocument = () => {
  const t = useTranslation()
  const [removeDocument, removeDocumentQuery] = useMutation(REMOVE_DOCUMENT)
  useErrorToast({ error: removeDocumentQuery?.error })
  useSuccessToast({
    success: removeDocumentQuery?.data,
    message: t('documents.documentRemovedSuccessfully')
  })

  return {
    removeDocument,
    removeDocumentQuery
  }
}
