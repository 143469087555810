import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const EDIT_ORDER = gql`
  mutation editOrder($orderId: Int!, $orderType: OrderType, $addressId: Int, $time: String, $deleteOrder: Boolean, $stripeSource: String, $state: String) {
    editOrder(orderId: $orderId, orderType: $orderType, addressId: $addressId, time: $time, deleteOrder: $deleteOrder, stripeSource: $stripeSource, state: $state) {
      orderId
    }
  }
`

const DELETE_ORDER = gql`
  mutation editOrder($orderId: Int!, $deleteOrder: Boolean) {
    editOrder(orderId: $orderId, deleteOrder: $deleteOrder) {
      fulfilledDate
    }
  }
`

export const useEditOrder = () => {
  const t = useTranslation()
  const [editOrder, editOrderQuery] = useMutation(EDIT_ORDER)
  const [deleteOrder, deleteOrderQuery] = useMutation(DELETE_ORDER)

  useErrorToast({ error: editOrderQuery?.error })
  useSuccessToast({
    success: !!editOrderQuery?.data,
    message: t('common.orderDeletedSuccessfully')
  })

  useErrorToast({ error: deleteOrderQuery?.error })
  useSuccessToast({
    success: !!deleteOrderQuery?.data,
    message: t('common.orderUpdatedSuccessfully')
  })
  return {
    editOrder,
    deleteOrder,
    editOrderQuery,
    deleteOrderQuery
  }
}
