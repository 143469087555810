import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const ADD_DOCTOR = gql`
  mutation CreatePrescriber(
    $firstName: String!
    $lastName: String!
    $license: String!
    $profession: Profession!
    $phoneNumber: String!
    $faxNumber: String!
    $streetAddressLineOne: String!
    $streetAddressLineTwo: String!
    $city: String!
    $province: String!
    $postalCode: String!
    $note: String!
  ) {
    createPrescriber(
      firstName: $firstName
      lastName: $lastName
      license: $license
      profession: $profession
      phoneNumber: $phoneNumber
      faxNumber: $faxNumber
      streetAddressLineOne: $streetAddressLineOne
      streetAddressLineTwo: $streetAddressLineTwo
      city: $city
      province: $province
      postalCode: $postalCode
      note: $note
    ) {
      license
    }
  }
`

export const useAddDoctor = () => {
  const t = useTranslation()
  const [addDoctor, addDoctorQuery] = useMutation(ADD_DOCTOR)
  useErrorToast({ error: addDoctorQuery?.error })
  useSuccessToast({
    success: !!addDoctorQuery?.data,
    message: t('common.doctorCreated')
  })

  return { addDoctor, addDoctorQuery }
}
