import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast, useSuccessToast } from '.'

const COMPLETE_TASK = gql`
  mutation completeTask($taskId: Int!, $notifyUser: Boolean) {
    completeTask(taskId: $taskId, notifyUser: $notifyUser) {
      taskId
    }
  }
`
export const useCompleteTask = () => {
  const t = useTranslation()
  const [completeTask, completeTaskQuery] = useMutation(COMPLETE_TASK)
  useErrorToast({ error: completeTaskQuery?.error })
  useSuccessToast({
    success: completeTaskQuery?.data,
    message: t('common.taskCompletedSuccessfully')
  })

  return {
    completeTask,
    completeTaskQuery
  }
}
