import { gql, useQuery } from '@apollo/client'

const ORDERS_IN_PROGRESS = gql`
  query($oAuthId: String!, $limit: Int) {
    userOrdersInProgress(oAuthId: $oAuthId, limit: $limit) {
      orderId
      oAuthId
      state
      orderItems {
        orderItemId
        prescriptionId
        refillQuantity
        drugId
        fillDate
        priceInCents
      }
      orderType
      comment
      addressId
      time
      stripeSource
      insuranceId
      shipstation_carrier_code
      shipstation_tracking_number
      shipstation_order_number
      shipstation_postal_code
      createdAt
    }
  }
`

const ORDERS_FULFILLED = gql`
  query {
    ordersFulfilled {
      orderId
      oAuthId
      state
      orderItems {
        orderItemId
        prescriptionId
        refillQuantity
        drugId
        fillDate
        priceInCents
      }
      orderType
      comment
      addressId
      time
      stripeSource
      insuranceId
      shipstation_carrier_code
      shipstation_tracking_number
      shipstation_order_number
      shipstation_postal_code
      createdAt
    }
  }
`

export interface IUseGetOrdersProps {
  userId: string
  type: 'inProgress' | 'fulfilled'
  limit: number
}

export const useGetOrders = ({ userId, type, limit }: IUseGetOrdersProps) => {
  const { data, error, loading, refetch } =
    type === 'inProgress'
      ? useQuery(ORDERS_IN_PROGRESS, {
          variables: {
            oAuthId: userId,
            limit: 100
          },
          fetchPolicy: 'network-only'
        })
      : useQuery(ORDERS_FULFILLED, {
          fetchPolicy: 'network-only'
        })

  return {
    orders: type === 'inProgress' ? data?.userOrdersInProgress : data?.ordersFulfilled,
    error,
    loading,
    refetch
  }
}
