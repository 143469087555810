import { gql, useQuery } from '@apollo/client'

const MOST_RECENT_MESSAGE = gql`
  query($oAuthId: String!) {
    messages(mostRecent: 1, oAuthId: $oAuthId) {
      messageId
      oAuthId
      messageText
      isAdmin
      createdAt
    }
  }
`
export interface IUseGetMostRecentMessageProps {
  oAuthId: string
}

export const useGetMostRecentMessage = ({ oAuthId }: IUseGetMostRecentMessageProps) =>
  useQuery(MOST_RECENT_MESSAGE, {
    variables: {
      oAuthId
    },
    fetchPolicy: 'network-only'
  })
