import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const USERS = gql`
  query($query: String) {
    users(query: $query) {
      oAuthId
      email
      birthday
      firstName
      lastName
      avatar
      phoneNumber
      isAdmin
      role
    }
  }
`
const ALL_USERS = gql`
  query AllSystemUsers($search: String) {
    allSystemUsers(search: $search) {
      oAuthId
      email
      birthday
      firstName
      lastName
      avatar
      role
      phoneNumber
      isAdmin
    }
  }
`

export interface IUseUserProps {
  query?: string
  isSuperAdmin?: boolean
}

export const useUsers = ({ query, isSuperAdmin }: IUseUserProps) => {
  const userQuery = useQuery(isSuperAdmin ? ALL_USERS : USERS, {
    variables: {
      [!isSuperAdmin ? 'query' : 'search']: query
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: userQuery.error })

  return userQuery
}
