import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const EDIT_ADDRESS = gql`
  mutation editAddress(
    $oAuthId: String
    $addressId: Int!
    $streetAddressLineOne: String
    $streetAddressLineTwo: String
    $city: String
    $province: String
    $postalCode: String
    $label: String
    $deactivated: Boolean
    $defaultAddress: DefaultAddress
  ) {
    editAddress(
      oAuthId: $oAuthId
      addressId: $addressId
      streetAddressLineOne: $streetAddressLineOne
      streetAddressLineTwo: $streetAddressLineTwo
      city: $city
      province: $province
      postalCode: $postalCode
      label: $label
      deactivated: $deactivated
      defaultAddress: $defaultAddress
    ) {
      addressId
    }
  }
`

export const useEditAddress = () => {
  const t = useTranslation()
  const [editAddress, editAddressQuery] = useMutation(EDIT_ADDRESS)
  useErrorToast({ error: editAddressQuery?.error })
  useSuccessToast({
    success: !!editAddressQuery?.data,
    message: t('common.addressUpdatedSuccessfully')
  })
  return {
    editAddress,
    editAddressQuery
  }
}
