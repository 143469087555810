export const useToken = () => {
  const getToken = async () => {
    const res = await fetch('/api/getToken', {
      method: 'POST'
    })

    const token = await res.json()
    return token?.token
  }

  return {
    getToken
  }
}
