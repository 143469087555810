import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useSuccessToast } from '.'
import { useErrorToast } from './useErrorToast'

const EDIT_USER = gql`
  mutation editUser(
    $oAuthId: String!
    $allergyGENcode: String
    $intoleranceGENcode: String
    $pathologyId: Int
    $descriptor: String
    $pharmacyId: Int
    $email: String
    $phoneNumber: String
    $firstName: String
    $lastName: String
    $birthday: String
    $gender: Gender
    $language: String
    $ramqNumber: String
    $stripeCustomerId: String
    $nonSafetyVial: Boolean
    $deceased: Boolean
    $killUser: Boolean
    $verifiedPhotoId: VerifiedPhotoId
    $domedicPatientId: String
    $logipharmId: String
    $verifiedPhotoIdNotification: Boolean
    $isGroup: Boolean
  ) {
    editUser(
      oAuthId: $oAuthId
      allergyGENcode: $allergyGENcode
      intoleranceGENcode: $intoleranceGENcode
      pathologyId: $pathologyId
      descriptor: $descriptor
      pharmacyId: $pharmacyId
      email: $email
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      birthday: $birthday
      gender: $gender
      language: $language
      ramqNumber: $ramqNumber
      stripeCustomerId: $stripeCustomerId
      nonSafetyVial: $nonSafetyVial
      deceased: $deceased
      verifiedPhotoId: $verifiedPhotoId
      killUser: $killUser
      domedicPatientId: $domedicPatientId
      logipharmId: $logipharmId
      verifiedPhotoIdNotification: $verifiedPhotoIdNotification
      isGroup: $isGroup
    )
  }
`

export const useEditUser = () => {
  const t = useTranslation()
  const [editUser, editUserQuery] = useMutation(EDIT_USER)
  useErrorToast({
    error: editUserQuery?.error
  })
  useSuccessToast({
    success: editUserQuery?.data,
    message: t('common.userUpdatedSuccessfully')
  })
  return {
    editUser,
    editUserQuery
  }
}
