import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const DELETE_ALLERGY_INTOLERANCE = gql`
  mutation deleteAllergyOrIntolerance($oAuthId: String!, $allergyGENcode: String, $intoleranceGENcode: String) {
    deleteAllergyOrIntolerance(oAuthId: $oAuthId, allergyGENcode: $allergyGENcode, intoleranceGENcode: $intoleranceGENcode)
  }
`

export const useDeleteAllergyOrIntolerance = () => {
  const [deleteAllergyIntolerance, deleteAllergyIntoleranceQuery] = useMutation(DELETE_ALLERGY_INTOLERANCE)
  useErrorToast({ error: deleteAllergyIntoleranceQuery?.error })

  return {
    deleteAllergyIntolerance,
    deleteAllergyIntoleranceQuery
  }
}
