import { gql, useQuery } from '@apollo/client'

const GET_ORDER = gql`
  query getOrder($orderId: Int!) {
    getOrder(orderId: $orderId) {
      orderId
      oAuthId
      orderItems {
        orderItemId
        prescriptionId
        refillQuantity
        drugId
        fillDate
        priceInCents
        prescription
      }
      state
      orderType
      comment
      addressId
      time
      stripeSource
      insuranceId
      createdAt
      createdBy
      fulfilledDate
    }
  }
`

export interface IUseGetOrderProps {
  orderId: number
}

export const useGetOrder = ({ orderId }: IUseGetOrderProps) =>
  useQuery(GET_ORDER, {
    variables: {
      orderId: orderId
    },
    fetchPolicy: 'network-only'
  })
