import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const EDIT_PRESCRIPTION = gql`
  mutation editPrescription(
    $prescriptionId: Int!
    $drugId: String
    $itemType: ItemType
    $prescribedDate: String
    $posology: String
    $note: String
    $refillDuration: Int
    $unitsPerRefill: Float
    $numberOfRefills: Int
    $classification: Classification
    $stoppedStatus: Boolean
    $stoppedReason: StoppedReason
    $gst: Boolean
    $qst: Boolean
  ) {
    editPrescription(
      prescriptionId: $prescriptionId
      drugId: $drugId
      itemType: $itemType
      prescribedDate: $prescribedDate
      posology: $posology
      note: $note
      refillDuration: $refillDuration
      unitsPerRefill: $unitsPerRefill
      numberOfRefills: $numberOfRefills
      classification: $classification
      stoppedStatus: $stoppedStatus
      stoppedReason: $stoppedReason
      gst: $gst
      qst: $qst
    ) {
      prescriptionId
    }
  }
`

export const useEditPrescription = () => {
  const t = useTranslation()
  const [editPrescription, editPrescriptionQuery] = useMutation(EDIT_PRESCRIPTION)
  useErrorToast({ error: editPrescriptionQuery?.error })

  useSuccessToast({
    success: !!editPrescriptionQuery?.data,
    message: t('common.prescriptionUpdatedSuccessfully')
  })
  return {
    editPrescription,
    editPrescriptionQuery
  }
}
