import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const GET_PHARMACY_USER_DETAILS = gql`
  query GetPharmacyUserDetails($oAuthId: String!) {
    getPharmacyUserDetails(oAuthId: $oAuthId) {
      email
      firstName
      gender
      language
      lastName
      logipharmId
      oAuthId
      note
      birthday
      role
      ramqNumber
      domedicPatientId
      phoneNumber
      stripeCustomerId
    }
  }
`

export interface IUseGetPharmacyUserDetailsProps {
  oAuthId?: string
}

export const useGetPharmacyUserDetails = ({ oAuthId }: IUseGetPharmacyUserDetailsProps) => {
  const pharmacyUserDetailsQuery = useQuery(GET_PHARMACY_USER_DETAILS, {
    variables: {
      oAuthId
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: pharmacyUserDetailsQuery?.error })

  return pharmacyUserDetailsQuery
}
