import { gql, useQuery } from '@apollo/client'

const ADDRESSES = gql`
  query getAddresses($oAuthId: String) {
    getAddresses(oAuthId: $oAuthId) {
      addressId
      oAuthId
      label
      streetAddressLineOne
      streetAddressLineTwo
      city
      province
      postalCode
      deactivated
      defaultAddress
      withinDeliveryRadius
    }
  }
`

export interface IUseGetAddressesProps {
  oAuthId: string
}

export const useGetAddresses = ({ oAuthId }: IUseGetAddressesProps) =>
  useQuery(ADDRESSES, {
    variables: {
      oAuthId
    },
    fetchPolicy: 'network-only'
  })
