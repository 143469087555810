import React, { useContext, useState } from 'react'
import { useGetPrescriptions } from '@/hooks'
import {
  UserPrescriptionsContext,
  UserProfileContext
} from '@/contexts'

export interface IPresciptionsContainerProps {
  children: any
}
export const PresciptionsContainer = ({
  children
}: IPresciptionsContainerProps) => {
  const { userId } = useContext(UserProfileContext)
  const [showStopped, setShowStopped] = useState<boolean>(false)
  const [
    selectedClassification,
    setSelectedClassification
  ] = useState<'all' | 'chronic' | 'PRN'>('all')

  const {
    data,
    loading,
    refetch,
    filteredPrescription
  } = useGetPrescriptions({
    userId: userId,
    showStopped: showStopped,
    classification: selectedClassification
  })

  const refetchAll = () => {
    refetch({ oAuthId: userId })
  }

  return (
    <UserPrescriptionsContext.Provider
      value={{
        prescriptions: data?.getPrescriptionsFromOAuthId,
        filteredPrescription,
        refetchAll,
        isLoading: loading,
        changeClassification: (v) => setSelectedClassification(v),
        changeStatus: (status) => setShowStopped(status !== 'active')
      }}
    >
      {children}
    </UserPrescriptionsContext.Provider>
  )
}
