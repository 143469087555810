import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'

export interface IUseSuccessToastProps {
  success: boolean
  message: string
}
export const useSuccessToast = ({ success, message }: IUseSuccessToastProps) => {
  const toast = useToast()

  useEffect(() => {
    success &&
      toast({
        title: message,
        isClosable: true,
        position: 'top'
      })
  }, [success])
}
