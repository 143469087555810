import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const LINK_PHARMACY_USER_WITH_USER = gql`
  mutation LinkPharmacyUserWithPharmacy($oAuthId: String!, $pharmacyId: Int!, $role: UserPharmacyRole!) {
    linkPharmacyUserWithPharmacy(oAuthId: $oAuthId, pharmacyId: $pharmacyId, role: $role) {
      id
    }
  }
`

export const useLinkPharmacyUserWithPharmacy = () => {
  const t = useTranslation()
  const [linkPharmacyUserWithPharmacy, linkPharmacyUserWithPharmacyQuery] = useMutation(LINK_PHARMACY_USER_WITH_USER)
  useErrorToast({ error: linkPharmacyUserWithPharmacyQuery?.error })
  useSuccessToast({
    success: !!linkPharmacyUserWithPharmacyQuery?.data,
    message: t('common.linkUserToEstablishmentSuccessfully')
  })
  return { linkPharmacyUserWithPharmacy, linkPharmacyUserWithPharmacyQuery }
}
