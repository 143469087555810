import { gql, useQuery } from '@apollo/client'

const CARDS = gql`
  query($oAuthId: String!) {
    getCards(oAuthId: $oAuthId) {
      id
      brand
      last4
      exp_month
      exp_year
      defaultSource
    }
  }
`

export interface IUseGetCardsProps {
  oAuthId: string
}

export const useGetCards = ({ oAuthId }: IUseGetCardsProps) =>
  useQuery(CARDS, {
    variables: {
      oAuthId
    },
    skip: !oAuthId,
    fetchPolicy: 'network-only'
  })
