import React from 'react'
import { Box, BoxProps, Spinner } from '@chakra-ui/react'

export interface ILoaderProps {
  fullWidth?: boolean
  height?: string | number
  spinColor?: 'blue.400'
  props?: BoxProps
}

export const Loader = ({
  fullWidth,
  height,
  spinColor = 'blue.400',
  props
}: ILoaderProps) => {
  return (
    <Box
      width={fullWidth ? '100%' : '20px'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      height={height || '20px'}
      {...props}
    >
      <Spinner color={spinColor} />
    </Box>
  )
}
