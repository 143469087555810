import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const DELETE_TASK_CATEGORY = gql`
  mutation RemoveTaskCategory($removeTaskCategoryId: Int!) {
    removeTaskCategory(id: $removeTaskCategoryId)
  }
`
export const useDeleteTaskCategory = () => {
  const t = useTranslation()
  const [deleteTaskCategory, deleteTaskCategoryQuery] = useMutation(DELETE_TASK_CATEGORY)
  useErrorToast({ error: deleteTaskCategoryQuery?.error })
  useSuccessToast({
    success: !!deleteTaskCategoryQuery?.data,
    message: t('common.taskCategoryDeleted')
  })
  return { deleteTaskCategory, deleteTaskCategoryQuery }
}
