import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useSuccessToast } from './useSuccessToast'

const CHARGE_ORDER = gql`
  mutation chargeOrder($orderId: String!, $amount: Int!) {
    chargeOrder(orderId: $orderId, amount: $amount)
  }
`

export const useChargeOrder = () => {
  const t = useTranslation()
  const [chargeOrder, chargeOrderQuery] = useMutation(CHARGE_ORDER)
  useSuccessToast({
    success: !!chargeOrderQuery?.data,
    message: t('common.orderChargedSuccessfully')
  })
  return {
    chargeOrder,
    chargeOrderQuery
  }
}
