import { IncomingMessage, ServerResponse } from 'http'
import { useMemo } from 'react'

import { ApolloClient, HttpLink, InMemoryCache, ServerError } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

export type ResolverContext = {
  req?: IncomingMessage
  res?: ServerResponse
}

function createApolloClient(token: string, pharmacyId: number) {
  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_API_URL
  })

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        'x-pharmacy-id': pharmacyId,
        authorization: `Bearer ${token}`
      }
    }
  })

  const logoutLink = onError(({ networkError }) => {
    if ((networkError as ServerError)?.statusCode === 401 && typeof window !== 'undefined') {
      window.location.pathname = '/api/auth/logout'
    }
  })

  return new ApolloClient<any>({
    ssrMode: typeof window === 'undefined',
    link: authLink.concat(logoutLink.concat(httpLink)),
    // @ts-ignore
    cache: new InMemoryCache()
  })
}

export function initializeApollo(initialState: any = null, token: string, pharmacyId: number) {
  return createApolloClient(token, pharmacyId)
}

export function useApollo(initialState: any, token: string, pharmacyId: number): ApolloClient<any> {
  return useMemo(() => initializeApollo(initialState, token, pharmacyId), [initialState, token, pharmacyId])
}
