import React, { useContext } from 'react'
import { useGetOrders } from '@/hooks'
import { UserOrdersContext, UserProfileContext } from '@/contexts'

export interface IOrdersContainerProps {
  children: any
}
export const OrdersContainer = ({
  children
}: IOrdersContainerProps) => {
  const { userId } = useContext(UserProfileContext)
  const { orders, loading, refetch } = useGetOrders({
    userId: userId,
    type: 'inProgress',
    limit: 100
  })

  const refetchAll = () => {
    refetch({ oAuthId: userId, type: 'inProgress', limit: 100 })
  }

  return (
    <UserOrdersContext.Provider
      value={{
        orders: orders,
        refetchAll,
        isLoading: loading
      }}
    >
      {children}
    </UserOrdersContext.Provider>
  )
}
