import { IPrescription } from '@/utils/types'
import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const PRESCRIPTIONS = gql`
  query($oAuthId: String!) {
    getPrescriptionsFromOAuthId(oAuthId: $oAuthId) {
      prescriptionId
      oAuthId
      itemType
      drug {
        drugId
        drugIdentificationNumber
        name
        form
        AHFS
        manufacturer
        strength
      }
      orderItems {
        orderItemId
        drugId
        orderId
        prescriptionId
        additionalPrescriptionId
        refillQuantity
        fillDate
        priceInCents
      }
      license
      dispenseType
      classification
      adherence
      posology
      prescribedDate
      numberOfRefills
      numberOfRefillsRemaining
      quantityRemaining
      validUntil
      refillDuration
      totalDuration
      totalQuantity
      unitsPerRefill
      note
      stoppedStatus
      stoppedReason
      imageIds
      transfers
      settings
      validityValues
      remainingREN
      remainingQuantity
      fraction
      prescribeDuration
      fillQuantity
      externalPrescriptionNumber
    }
  }
`

export interface IUseGetPrescriptionsProps {
  userId: string
  showStopped: boolean
  classification?: 'all' | 'chronic' | 'PRN'
}

export const useGetPrescriptions = ({ userId, showStopped, classification }: IUseGetPrescriptionsProps) => {
  const prescriptionQuery = useQuery(PRESCRIPTIONS, {
    variables: {
      oAuthId: userId
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: prescriptionQuery?.error })

  return {
    ...prescriptionQuery,
    filteredPrescription: prescriptionQuery?.data?.getPrescriptionsFromOAuthId.filter((p: IPrescription) =>
      p.stoppedStatus === showStopped && classification === 'all' ? true : p.classification === classification
    )
  }
}
