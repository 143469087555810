import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const EDIT_EMPEGO_LINK = gql`
  mutation EditEmpegoLink($editEmpegoLinkId: Int!, $pathologyEn: String, $pathologyFr: String, $preCodeLink: String, $postCodeLink: String) {
    editEmpegoLink(id: $editEmpegoLinkId, pathologyEn: $pathologyEn, pathologyFr: $pathologyFr, preCodeLink: $preCodeLink, postCodeLink: $postCodeLink) {
      pathologyEn
      pathologyFr
    }
  }
`

export const useEditEmpegoLink = () => {
  const t = useTranslation()
  const [editEmpegoLink, editEmpegoLinkQuery] = useMutation(EDIT_EMPEGO_LINK)
  useErrorToast({ error: editEmpegoLinkQuery?.error })
  useSuccessToast({
    success: !!editEmpegoLinkQuery?.data,
    message: t('empegoLink.empegoLinkUpdated')
  })

  return { editEmpegoLink, editEmpegoLinkQuery }
}
