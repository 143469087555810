import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const CREATE_TASK_CATEGORY = gql`
  mutation CreateTaskCategory($name: String!, $nameFr: String!, $description: String, $descriptionFr: String) {
    createTaskCategory(name: $name, nameFr: $nameFr, description: $description, descriptionFr: $descriptionFr) {
      id
      name
      nameFr
      description
      descriptionFr
      isDeleted
    }
  }
`
export const useCreateTaskCategory = () => {
  const t = useTranslation()
  const [createTaskCategory, createTaskCategoryQuery] = useMutation(CREATE_TASK_CATEGORY)
  useErrorToast({ error: createTaskCategoryQuery?.error })
  useSuccessToast({
    success: !!createTaskCategoryQuery?.data,
    message: t('common.taskCategoryCreated')
  })
  return { createTaskCategory, createTaskCategoryQuery }
}
