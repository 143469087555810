import { gql, useQuery } from '@apollo/client'

const INSURANCES = gql`
  query insurances($oAuthId: String) {
    insurances(oAuthId: $oAuthId) {
      insuranceId
      oAuthId
      imageIds
      name
      fields
      verified
      priority
      archived
    }
  }
`

export interface IUseGetAllInsurancesProps {
  oAuthId: string
}

export const useGetAllInsurances = ({ oAuthId }: IUseGetAllInsurancesProps) =>
  useQuery(INSURANCES, {
    variables: {
      oAuthId
    },
    fetchPolicy: 'network-only'
  })
