import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const CREATE_USER = gql`
  mutation CreateUser($userInput: UserInput!) {
    createUser(userInput: $userInput) {
      birthday
      domedicPatientId
      email
      firstName
      gender
      language
      lastName
      logipharmId
      phoneNumber
      ramqNumber
      role
      stripeCustomerId
      pharmacyId
    }
  }
`
export const useCreateUser = () => {
  const t = useTranslation()
  const [createUser, createUserQuery] = useMutation(CREATE_USER)
  useErrorToast({ error: createUserQuery?.error })
  useSuccessToast({
    success: !!createUserQuery?.data,
    message: t('common.userCreated')
  })
  return { createUser, createUserQuery }
}
