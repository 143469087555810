import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const DELETE_USER = gql`
  mutation DeleteUser($oAuthId: String!) {
    deleteUser(oAuthId: $oAuthId)
  }
`

export const useDeleteUser = () => {
  const t = useTranslation()
  const [deleteUser, deleteUserQuery] = useMutation(DELETE_USER)
  useErrorToast({ error: deleteUserQuery?.error })
  useSuccessToast({
    success: !!deleteUserQuery?.data,
    message: t('common.clientDeletedSuccessfully')
  })
  return { deleteUser, deleteUserQuery }
}
