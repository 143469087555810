import { createContext } from 'react'
import { IPrescription } from '@/utils/types'
import { noop } from 'lodash'

export interface IPrescriptionContext {
  prescriptions: IPrescription[]
  filteredPrescription: IPrescription[]
  refetchAll: () => void
  isLoading: boolean
  changeStatus: (status: 'active' | 'stopped') => void
  changeClassification: (classication: 'all' | 'chronic' | 'PRN') => void
}
export const UserPrescriptionsContext = createContext<IPrescriptionContext>({
  prescriptions: [],
  filteredPrescription: [],
  refetchAll: noop,
  isLoading: false,
  changeStatus: noop,
  changeClassification: noop
})
