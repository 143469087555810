import React from 'react'
import { useGetUserById } from '@/hooks'
import { useRouter } from 'next/router'
import { UserProfileContext } from '.'

export interface IUserProfileContainerProps {
  children: any
}

export const UserProfileContainer = ({
  children
}: IUserProfileContainerProps) => {
  const router = useRouter()

  const { userId, tab } = router.query

  const { data, loading, refetch } = useGetUserById({
    userId: `auth0|${userId}`
  })

  const userRefetch = () => {
    refetch({
      oAuthId: `auth0|${userId}`
    })
  }
  return (
    <UserProfileContext.Provider
      value={{
        userId: `auth0|${userId}` as string,
        user: data?.user,
        refetchUser: userRefetch,
        isLoading: loading,
        currentTab: +tab
      }}
    >
      {children}
    </UserProfileContext.Provider>
  )
}
