import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const EMPEGO_LINKS = gql`
  query EmpegoLinks {
    empegoLinks {
      empegoCode
      fullLink
      id
      pathologyEn
      pathologyFr
      postCodeLink
      preCodeLink
    }
  }
`

export const useGetEmpegoLinks = () => {
  const empegoLinksQuery = useQuery(EMPEGO_LINKS, {
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: empegoLinksQuery?.error })

  return empegoLinksQuery
}
