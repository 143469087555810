import { createContext } from 'react'
import { IOrder } from '@/utils/types'
import { noop } from 'lodash'

export interface IOrdersContext {
  orders: IOrder[]
  refetchAll: () => void
  isLoading: boolean
}
export const UserOrdersContext = createContext<IOrdersContext>({
  orders: [],
  refetchAll: noop,
  isLoading: false
})
