import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const APPROVE_ORDER = gql`
  mutation approveOrder($orderId: Int!, $notifyUser: Boolean!, $approveAsPaid: Boolean!) {
    approveOrder(orderId: $orderId, notifyUser: $notifyUser, approveAsPaid: $approveAsPaid) {
      orderId
    }
  }
`

export const useApproveOrder = () => {
  const t = useTranslation()
  const [approveOrder, approveOrderQuery] = useMutation(APPROVE_ORDER)
  useErrorToast({ error: approveOrderQuery?.error })
  useSuccessToast({
    success: !!approveOrderQuery?.data,
    message: t('common.orderApprovedSuccessfully')
  })
  return {
    approveOrder,
    approveOrderQuery
  }
}
