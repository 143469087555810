import { useToast } from '@chakra-ui/react'
import { useEffect } from 'react'

export interface IUseErrorToastProps {
  error: any
  message?: string
}
export const useErrorToast = ({ error, message }: IUseErrorToastProps) => {
  const toast = useToast()

  useEffect(() => {
    error &&
      toast({
        title: message || error?.message,
        status: 'error',
        position: 'top'
      })
  }, [error])
}
