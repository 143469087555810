import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const EDIT_ORDER_ITEM_PRICE = gql`
  mutation setOrderItemPrice($orderItemId: Int!, $priceInCents: Int!) {
    setOrderItemPrice(orderItemId: $orderItemId, priceInCents: $priceInCents) {
      orderItemId
    }
  }
`

export const useEditOrderItemPrice = () => {
  const [editOrderItemPrice, editOrderItemPriceQuery] = useMutation(EDIT_ORDER_ITEM_PRICE)
  useErrorToast({ error: editOrderItemPriceQuery?.error })
  return {
    editOrderItemPrice,
    editOrderItemPriceQuery
  }
}
