import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const CLIENTS = gql`
  query ClientPharmacies($type: clientPharmacyType!, $search: String) {
    clientPharmacies(type: $type, search: $search) {
      address
      bannerLogo
      bannerName
      businessHours
      email
      name
      city
      pharmacyId
      phone
      postalCode
      province
      bannerName
      website
    }
  }
`

export interface IUseGetClientsProps {
  type: string
  search?: string
}

export const useGetClients = ({ type, search }: IUseGetClientsProps) => {
  const clientsQuery = useQuery(CLIENTS, {
    variables: {
      type,
      search
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: clientsQuery?.error })

  return clientsQuery
}
