import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const ADD_EMPEGO_LINK = gql`
  mutation AddEmpegoLink($addEmpegoLinkInput: AddEmpegoLinkInput!) {
    addEmpegoLink(addEmpegoLinkInput: $addEmpegoLinkInput) {
      empegoCode
      id
      fullLink
      pathologyEn
      pathologyFr
    }
  }
`

export const useAddEmpegoLink = () => {
  const t = useTranslation()
  const [addEmpegoLink, addEmpegoLinkQuery] = useMutation(ADD_EMPEGO_LINK)
  useErrorToast({ error: addEmpegoLinkQuery?.error })
  useSuccessToast({
    success: !!addEmpegoLinkQuery?.data,
    message: t('empegoLink.empegoLinkAdded')
  })

  return { addEmpegoLink, addEmpegoLinkQuery }
}
