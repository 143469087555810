import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const ADD_PRESET_MESSAGE = gql`
  mutation AddPresetMessage($titleEn: String!, $messageEn: String!, $titleFr: String!, $messageFr: String!) {
    addPresetMessage(titleEn: $titleEn, messageEn: $messageEn, titleFr: $titleFr, messageFr: $messageFr) {
      id
      isDeleted
      messageEn
      messageFr
      titleEn
      titleFr
    }
  }
`

export const useAddPresetMessage = () => {
  const t = useTranslation()
  const [addPresetMessage, addPresetMessageQuery] = useMutation(ADD_PRESET_MESSAGE)
  useErrorToast({ error: addPresetMessageQuery?.error })
  useSuccessToast({
    success: !!addPresetMessageQuery?.data,
    message: t('predefinedMessage.addedSuccessfully')
  })
  return { addPresetMessage, addPresetMessageQuery }
}
