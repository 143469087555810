import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast, useSuccessToast } from '.'

const ADD_DOCUMENTS = gql`
  mutation AddDocuments($addDocumentsInput: AddDocumentsInput!) {
    addDocuments(addDocumentsInput: $addDocumentsInput)
  }
`

export type IUseAddDocumentsProps = {
  showSuccess?: boolean
}

export const useAddDocuments = ({ showSuccess }: IUseAddDocumentsProps) => {
  const t = useTranslation()
  const [addDocuments, addDocumentsQuery] = useMutation(ADD_DOCUMENTS)
  useErrorToast({ error: addDocumentsQuery?.error })
  showSuccess &&
    useSuccessToast({
      success: addDocumentsQuery?.data,
      message: t('common.documentsAddedSuccessfully')
    })

  return {
    addDocuments,
    addDocumentsQuery
  }
}
