import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const EDIT_ORDER_ITEM = gql`
  mutation editOrderItem($orderItemId: Int!, $fillDate: String) {
    editOrderItem(orderItemId: $orderItemId, fillDate: $fillDate) {
      orderItemId
    }
  }
`

export const useEditOrderItem = () => {
  const [editOrderItem, editOrderItemQuery] = useMutation(EDIT_ORDER_ITEM)
  useErrorToast({ error: editOrderItemQuery?.error })

  return {
    editOrderItem,
    editOrderItemQuery
  }
}
