import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useTranslation } from '@/utils/i18n'

const DELETE_INSURANCE = gql`
  mutation deleteInsurance($insuranceId: Int!) {
    deleteInsurance(insuranceId: $insuranceId)
  }
`

export const useDeleteInsurance = () => {
  const t = useTranslation()
  const [deleteInsurance, deleteInsuranceQuery] = useMutation(DELETE_INSURANCE)
  useErrorToast({
    error: deleteInsuranceQuery?.error || deleteInsuranceQuery?.data?.deleteInsurance,
    message: t('common.failDeleteInsurance')
  })

  return {
    deleteInsurance,
    deleteInsuranceQuery
  }
}
