import { useEffect, useState } from 'react'
import { useToken } from './useToken'

export interface IUseGetImages {
  imagesIds: any
  enabled?: boolean
}

export const useGetImages = ({ imagesIds, enabled = true }: IUseGetImages) => {
  const [imageUris, setImageUris] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const { getToken } = useToken()

  useEffect(() => {
    const fetchImages = async (imagesIds) => {
      setLoading(true)
      const array = imagesIds?.map(async (imgid: number) => {
        const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/image/${imgid}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + (await getToken())
          }
        })
        return { id: imgid, uri: await res.text() }
      })

      const res = await Promise.all(array)
      setImageUris(res)
      setLoading(false)
    }

    if (imagesIds?.length && enabled) {
      fetchImages(imagesIds)
    }
  }, [imagesIds, enabled])
  return {
    imageUris,
    loading
  }
}
