import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const TASKS = gql`
  query tasks($status: [TaskStatus!]!, $typeOfTask: [Int]!) {
    tasks(statusFilter: $status, typeOfTaskFilter: $typeOfTask) {
      taskId
      data
      typeOfTask
      completed
      createdAt
      oAuthId
      userFirstName
      userLastName
      priority
      title
      assignedTo
      attachedFileIds
      completedAt
      dueDate
      description
      status
      manuallyCreated
      taskTypeCategory
    }
  }
`
export type TaskStatus = 'todo' | 'inprogress' | 'canceled' | 'completed'

export interface IUseGetTasksProps {
  status: TaskStatus[]
  typeOfTask: number[]
}

export const useGetTasks = ({ status, typeOfTask }: IUseGetTasksProps) => {
  const taskQuery = useQuery(TASKS, {
    variables: {
      status,
      typeOfTask
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: taskQuery?.error })

  return taskQuery
}
