import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const DOCTORS = gql`
  query Prescribers($query: String) {
    prescribers(query: $query) {
      license
      firstName
      lastName
      profession
      phoneNumber
      faxNumber
      streetAddressLineOne
      streetAddressLineTwo
      city
      province
      note
      postalCode
    }
  }
`

export interface IUseGetDoctorsProps {
  query?: string
}

export const useGetDoctors = ({ query }: IUseGetDoctorsProps) => {
  const doctorsQuery = useQuery(DOCTORS, {
    variables: {
      query
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: doctorsQuery?.error })

  return doctorsQuery
}
