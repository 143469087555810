import { createContext } from 'react'
import { IInsurance } from '@/utils/types'
import { noop } from 'lodash'

export interface IInsuranceContext {
  insurances: IInsurance[]
  refetchAll: () => void
  isLoading: boolean
}
export const UserInsuranceContext = createContext<IInsuranceContext>({
  insurances: [],
  refetchAll: noop,
  isLoading: false
})
