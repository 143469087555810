import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const EDIT_REFILL_QTY = gql`
  mutation editRefillQuantity($prescriptionId: Int!, $refillQuantity: Float!) {
    editRefillQuantity(prescriptionId: $prescriptionId, refillQuantity: $refillQuantity)
  }
`

export const useEditRefillQty = () => {
  const [editRefillQty, editRefillQtyQuery] = useMutation(EDIT_REFILL_QTY)
  useErrorToast({ error: editRefillQtyQuery?.error })
  return {
    editRefillQty,
    editRefillQtyQuery
  }
}
