import { gql, useLazyQuery, useQuery } from '@apollo/client'

const ME = gql`
  query($oAuthId: String) {
    user(oAuthId: $oAuthId) {
      firstName
      lastName
      email
      oAuthId
      pharmacyId
    }
  }
`

const CURRENT_USER = gql`
  query CurrentUser($activeId: String) {
    currentUser(activeId: $activeId) {
      isSuperAdmin
    }
  }
`

export interface IUseGetMeProps {
  oAuthId?: string
}

export const useGetMe = ({ oAuthId }: IUseGetMeProps) => [
  useQuery(ME, {
    variables: {
      oAuthId
    },
    fetchPolicy: 'network-only'
  }),
  useQuery(CURRENT_USER, {
    variables: {
      activeId: oAuthId
    },
    fetchPolicy: 'network-only'
  })
]

export const useLazyGetMe = () => useLazyQuery(ME)
