import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const ADD_SCHEDULED_MESSAGES = gql`
  mutation AddScheduledMessage(
    $destinationOAuthId: String!
    $title: String!
    $startAt: String!
    $sendAsBot: Boolean!
    $hasReoccurrence: Boolean!
    $reoccurringType: String
    $reoccurringDates: JSON
    $stopAt: String
    $messageText: String
    $stopAfterCount: Int
  ) {
    addScheduledMessage(
      destinationOAuthId: $destinationOAuthId
      title: $title
      startAt: $startAt
      sendAsBot: $sendAsBot
      hasReoccurrence: $hasReoccurrence
      reoccurringType: $reoccurringType
      reoccurringDates: $reoccurringDates
      stopAt: $stopAt
      messageText: $messageText
      stopAfterCount: $stopAfterCount
    ) {
      createdAt
      deliveredAt
    }
  }
`

export const useAddScheduledMessage = () => {
  const t = useTranslation()
  const [addScheduledMessage, addScheduledMessageQuery] = useMutation(ADD_SCHEDULED_MESSAGES)
  useErrorToast({ error: addScheduledMessageQuery?.error })
  useSuccessToast({
    success: !!addScheduledMessageQuery?.data,
    message: t('message.scheduledSuccessfully')
  })

  return { addScheduledMessage, addScheduledMessageQuery }
}
