import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const FULLFILL_ORDER = gql`
  mutation fulfillOrder($orderId: Int!) {
    fulfillOrder(orderId: $orderId) {
      orderId
    }
  }
`

export const useFulFillOrder = () => {
  const [fullFillOrder, fullFillOrderQuery] = useMutation(FULLFILL_ORDER)
  useErrorToast({ error: fullFillOrderQuery?.error })
  return {
    fullFillOrder,
    fullFillOrderQuery
  }
}
