import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const DELETE_EMPEGO_LINK = gql`
  mutation DeleteEmpegoLink($deleteEmpegoLinkId: Int!) {
    deleteEmpegoLink(id: $deleteEmpegoLinkId)
  }
`

export const useDeleteEmpegoLink = () => {
  const t = useTranslation()
  const [deleteEmpegoLink, deleteEmpegoLinkQuery] = useMutation(DELETE_EMPEGO_LINK)
  useErrorToast({ error: deleteEmpegoLinkQuery?.error })
  useSuccessToast({
    success: !!deleteEmpegoLinkQuery?.data,
    message: t('empegoLink.empegoLinkDeleted')
  })

  return { deleteEmpegoLink, deleteEmpegoLinkQuery }
}
