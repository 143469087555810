import { gql, useQuery } from '@apollo/client'

const PHARMACY_EMPEGO_LINKS = gql`
  query PharmacyStaffsEmpegoLinks {
    pharmacyStaffsEmpegoLinks {
      empegoCode
      postCodeLink
      preCodeLink
      fullLink
      pathologyFr
      pathologyEn
    }
  }
`

export interface IPharmacyStaffsEmpegoLinkResult {
  pharmacyStaffsEmpegoLinks?: IPharmacyStaffsEmpegoLink[];
}

export interface IPharmacyStaffsEmpegoLink {
  empegoCode: string;
  postCodeLink: string;
  preCodeLink: string;
  fullLink: string;
  pathologyFr: string;
  pathologyEn: string;
}

export const useGetPharmacyStaffsEmpegoLinks = () => {
  return useQuery<IPharmacyStaffsEmpegoLinkResult>(PHARMACY_EMPEGO_LINKS, {
    fetchPolicy: 'network-only'
  })
}
