import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const DRUG = gql`
  query GetDrug($drugId: String, $language: String) {
    getDrug(drugId: $drugId, language: $language) {
      drugId
      GENcode
      defaultPosology
      name
      legalStatusQc
      strength
      form
      formFrLowercase
      formEnLowercase
      strengthEnLowercase
      strengthFrLowercase
    }
  }
`

export interface IUseGetDrugProps {
  drugId: string
  language: 'en' | 'fr'
}

export const useGetDrug = ({ drugId, language }: IUseGetDrugProps) => {
  const drugQuery = useQuery(DRUG, {
    variables: {
      drugId,
      language
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: drugQuery?.error })

  return drugQuery
}
