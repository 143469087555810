import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const documentTypes = gql`
  query FetchDocumentTypes {
    fetchDocumentTypes {
      createdAt
      id
      isDeleted
      name
    }
  }
`

export const useFetchDocumentTypes = () => {
  const documentTypesQuery = useQuery(documentTypes, {
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: documentTypesQuery?.error })

  return documentTypesQuery
}
