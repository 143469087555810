import { gql, useQuery } from '@apollo/client'

const PATHOLOGIES = gql`
  query pathologies($query: String) {
    pathologies(query: $query) {
      pathologyId
      pathologyNameEn
      pathologyNameFr
    }
  }
`

export interface IUseGetAllPathologiesProps {
  query: string
}

export const useGetAllPathologies = ({ query }: IUseGetAllPathologiesProps) =>
  useQuery(PATHOLOGIES, {
    variables: {
      query
    },
    fetchPolicy: 'network-only'
  })
