import React, { useContext } from 'react'
import { useGetAllInsurances } from '@/hooks/useGetAllInsurances'
import { UserInsuranceContext } from '.'
import { UserProfileContext } from '..'

export interface IInsurancesContainerProps {
  children: any
}
export const InsurancesContainer = ({
  children
}: IInsurancesContainerProps) => {
  const { userId } = useContext(UserProfileContext)
  const { data, loading, refetch } = useGetAllInsurances({
    oAuthId: userId
  })

  const refetchAll = () => {
    refetch({ oAuthId: userId })
  }

  return (
    <UserInsuranceContext.Provider
      value={{
        insurances: data?.insurances,
        refetchAll,
        isLoading: loading
      }}
    >
      {children}
    </UserInsuranceContext.Provider>
  )
}
