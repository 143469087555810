import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const PHARMACIES = gql`
  query Pharmacies {
    pharmacies {
      pharmacyId
      name
    }
  }
`

export const useGetPharmacies = () => {
  const pharmaciesQuery = useQuery(PHARMACIES, {
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: pharmaciesQuery?.error })

  return pharmaciesQuery
}
