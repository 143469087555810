import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const EDIT_TASK_CATEGORY = gql`
  mutation EditTaskCategory($editTaskCategoryId: Int!, $name: String, $nameFr: String, $description: String, $descriptionFr: String) {
    editTaskCategory(id: $editTaskCategoryId, name: $name, nameFr: $nameFr, description: $description, descriptionFr: $descriptionFr) {
      id
      name
      nameFr
      description
      descriptionFr
      isDeleted
    }
  }
`

export const useEditTaskCategory = () => {
  const t = useTranslation()
  const [editTaskCategory, editTaskCategoryQuery] = useMutation(EDIT_TASK_CATEGORY)
  useErrorToast({ error: editTaskCategoryQuery?.error })
  useSuccessToast({
    success: !!editTaskCategoryQuery?.data,
    message: t('common.taskCategoryUpdated')
  })
  return { editTaskCategory, editTaskCategoryQuery }
}
