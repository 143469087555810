import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const GET_PRESCRIPTION_BY_ID = gql`
  query getPrescriptionFromPrescriptionId($prescriptionId: Int!) {
    getPrescriptionFromPrescriptionId(prescriptionId: $prescriptionId) {
      prescriptionId
      oAuthId
      itemType
      drug {
        drugId
        drugIdentificationNumber
        name
        form
        AHFS
        manufacturer
        strength
      }
      orderItems {
        orderItemId
        drugId
        orderId
        prescriptionId
        additionalPrescriptionId
        refillQuantity
        fillDate
        priceInCents
      }
      license
      dispenseType
      classification
      adherence
      posology
      prescribedDate
      numberOfRefills
      numberOfRefillsRemaining
      quantityRemaining
      validUntil
      refillDuration
      totalDuration
      totalQuantity
      unitsPerRefill
      note
      stoppedStatus
      stoppedReason
      imageIds
      transfers
      settings
      validityValues
      remainingREN
      remainingQuantity
      fraction
      prescribeDuration
      fillQuantity
      externalPrescriptionNumber
    }
  }
`

export interface IUseGetPrescriptionByIdProps {
  prescriptionId: number
}

export const useGetPrescriptionById = ({ prescriptionId }: IUseGetPrescriptionByIdProps) => {
  const query = useQuery(GET_PRESCRIPTION_BY_ID, {
    variables: {
      prescriptionId: prescriptionId
    },
    fetchPolicy: 'network-only'
  })
  useErrorToast({
    error: query?.error,
    message: 'Failed to get prescription'
  })
  return query
}
