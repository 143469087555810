import { gql, useQuery } from '@apollo/client'

const USER = gql`
  query($oAuthId: String) {
    user(oAuthId: $oAuthId) {
      oAuthId
      email
      birthday
      firstName
      lastName
      phoneNumber
      pharmacyId
      ramqNumber
      gender
      verifiedPhotoId
      role
      logipharmId
      domedicPatientId
      language
      allergies
      pathologies
      photoId
      verifiedPhotoId
      intolerances
      avatar
      deceased
      lastCheckedNotifications
      nonSafetyVial
      stripeCustomerId
      isGroup
      children {
        oAuthId
        email
        firstName
        lastName
      }
    }
  }
`

export interface IUseGetUserByIdProps {
  userId: string
}

export const useGetUserById = ({ userId }: IUseGetUserByIdProps) =>
  useQuery(USER, {
    variables: {
      oAuthId: userId
    },
    fetchPolicy: 'network-only'
  })
