// mutation editDrug($drugInput: DrugInput!, $defaultPosology: String) {

import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const EDIT_DRUG = gql`
  mutation EditDrugDetails(
    $drugId: String!
    $defaultPosology: String
    $drugIdentificationNumber: String
    $nameFrCapitalized: String
    $nameEnCapitalized: String
    $strengthFrLowercase: String
    $strengthEnLowercase: String
    $formFrLowercase: String
    $formEnLowercase: String
    $videoUrl: String
  ) {
    editDrugDetails(
      drugId: $drugId
      defaultPosology: $defaultPosology
      drugIdentificationNumber: $drugIdentificationNumber
      nameFrCapitalized: $nameFrCapitalized
      nameEnCapitalized: $nameEnCapitalized
      strengthFrLowercase: $strengthFrLowercase
      strengthEnLowercase: $strengthEnLowercase
      formFrLowercase: $formFrLowercase
      formEnLowercase: $formEnLowercase
      videoUrl: $videoUrl
    ) {
      drugId
      nameEnCapitalized
    }
  }
`

export const useEditDrug = () => {
  const t = useTranslation()
  const [editDrug, editDrugQuery] = useMutation(EDIT_DRUG)
  useErrorToast({ error: editDrugQuery?.error })
  useSuccessToast({
    success: !!editDrugQuery?.data,
    message: t('common.drugEdited')
  })

  return { editDrug, editDrugQuery }
}
