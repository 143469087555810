import { gql, useQuery } from '@apollo/client'

const IMAGES = gql`
  query getUserImages($oAuthId: String) {
    getUserImages(oAuthId: $oAuthId) {
      data
    }
  }
`

export interface IUseGetDocumentsProps {
  oAuthId: string
}

export const useGetDocuments = ({ oAuthId }: IUseGetDocumentsProps) =>
  useQuery(IMAGES, {
    variables: {
      oAuthId
    },
    fetchPolicy: 'network-only'
  })
