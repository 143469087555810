import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const DELETE_PATHOLOGY = gql`
  mutation deletePathology($oAuthId: String!, $pathologyId: Int!) {
    deletePathology(oAuthId: $oAuthId, pathologyId: $pathologyId)
  }
`

export const useDeletePathology = () => {
  const [deletePathology, deletePathologyQuery] = useMutation(DELETE_PATHOLOGY)
  useErrorToast({ error: deletePathologyQuery?.error })
  return {
    deletePathology,
    deletePathologyQuery
  }
}
