import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const GET_PHARMACY_USERS = gql`
  query GetPharmacyUsers($type: pharmacyUserClassification!, $search: String, $pharmacyId: Int!) {
    getPharmacyUsers(type: $type, search: $search, pharmacyId: $pharmacyId) {
      userDetails {
        avatar
        birthday
        email
        firstName
        lastName
        oAuthId
        phoneNumber
      }
      role
      pharmacyName
      pharmacyId
      pharmacyAddress
      oAuthId
      id
    }
  }
`

export interface IUseGetPharmacyUsersProps {
  search?: string
  pharmacyId: number
  type: 'EMPLOYEE' | 'CUSTOMER'
  enabled?: boolean
}

export const useGetPharmacyUsers = ({ search, pharmacyId, type, enabled }: IUseGetPharmacyUsersProps) => {
  const pharmacyUsersQuery = useQuery(GET_PHARMACY_USERS, {
    skip: !enabled,
    variables: {
      search,
      pharmacyId,
      type
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: pharmacyUsersQuery?.error })

  return pharmacyUsersQuery
}
