import { useGetMe } from '@/hooks/useGetMe'
import { useUser } from '@auth0/nextjs-auth0'
import { useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { GraphQLContext } from '../GraphQLProvider'
import { MeContext } from './meContext'
import { useTranslation } from '@/utils/i18n'

export interface IMeProviderProps {
  children: any
}

export const MeProvider = ({ children }: IMeProviderProps) => {
  const t = useTranslation()
  const { user } = useUser()
  const router = useRouter()
  const { changePharmacyId } = useContext(GraphQLContext)
  const toast = useToast()

  const [{ data, loading: userLoading, refetch, called }, { data: current, loading: loadingCurrentUser, refetch: refetchCurrent }] = useGetMe({
    oAuthId: user?.sub
  })

  useEffect(() => {
    if (called && !userLoading) {
      if (data?.user) {
        changePharmacyId(data?.user.pharmacyId || 0)
      } else {
        toast({
          title: t('common.unauthorizedAccess'),
          status: 'error',
          position: 'top'
        })
        router.push('/api/auth/logout')
      }
    }
  }, [data])

  return (
    <MeContext.Provider
      value={{
        me: { ...(data?.user ?? {}), ...(current?.currentUser ?? {}) } ?? {},
        userLoading: userLoading || loadingCurrentUser,
        refetchMe: () => {
          refetch({ oAuthId: user?.sub })
          refetchCurrent({ oAuthId: user?.sub })
        }
      }}
    >
      {children}
    </MeContext.Provider>
  )
}

export const useGetMeContext = () => useContext(MeContext)
