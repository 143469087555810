import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const EDIT_DOCTOR = gql`
  mutation EditPrescriber(
    $license: String!
    $firstName: String!
    $lastName: String!
    $profession: Profession!
    $phoneNumber: String!
    $faxNumber: String!
    $streetAddressLineOne: String!
    $streetAddressLineTwo: String!
    $city: String!
    $province: String!
    $postalCode: String!
    $note: String!
  ) {
    editPrescriber(
      license: $license
      firstName: $firstName
      lastName: $lastName
      profession: $profession
      phoneNumber: $phoneNumber
      faxNumber: $faxNumber
      streetAddressLineOne: $streetAddressLineOne
      streetAddressLineTwo: $streetAddressLineTwo
      city: $city
      province: $province
      postalCode: $postalCode
      note: $note
    ) {
      license
    }
  }
`

export const useEditDoctor = () => {
  const t = useTranslation()
  const [editDoctor, editDoctorQuery] = useMutation(EDIT_DOCTOR)
  useErrorToast({ error: editDoctorQuery?.error })
  useSuccessToast({
    success: !!editDoctorQuery?.data,
    message: t('common.doctorEdited')
  })

  return { editDoctor, editDoctorQuery }
}
