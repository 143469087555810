import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const PHARMACY = gql`
  query GetPharmacy($pharmacyId: Int) {
    getPharmacy(pharmacyId: $pharmacyId) {
      address
      bannerLogo
      bannerName
      businessHours
      city
      email
      isActive
      name
      pharmacyId
      phone
      postalCode
      province
      website
      empegoCode
    }
  }
`

export interface IUseGetPharmacyProps {
  pharmacyId: number
}

export const useGetPharmacy = ({ pharmacyId }: IUseGetPharmacyProps) => {
  const pharmacyQuery = useQuery(PHARMACY, {
    variables: {
      pharmacyId
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: pharmacyQuery?.error })

  return pharmacyQuery
}
