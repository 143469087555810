import { gql, useQuery } from '@apollo/client'

const ALLERGIES = gql`
  query allergies($query: String) {
    allergies(query: $query) {
      GENcode
      genericNameEn
      genericNameFr
    }
  }
`

export interface IUseGetAllergiesProps {
  query: string
}

export const useGetAllergies = ({ query }: IUseGetAllergiesProps) =>
  useQuery(ALLERGIES, {
    variables: {
      query
    },
    fetchPolicy: 'network-only'
  })
