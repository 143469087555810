import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const DRUGS = gql`
  query Drugs($query: String, $language: String, $page: Int, $perPage: Int) {
    drugs(query: $query, language: $language, page: $page, per_page: $perPage) {
      page
      total_count
      total_pages
      drugDetails {
        form
        strength
        drugId
        GENcode
        legalStatusQc
        name
        drugIdentificationNumber
        genericInfo {
          name
        }
        formEnLowercase
        formFrLowercase
        nameEnCapitalized
        nameFrCapitalized
        defaultPosology
        strengthEnLowercase
        strengthFrLowercase
        videoUrl
      }
    }
  }
`

export interface IUseGetDrugsProps {
  query: string
  language: string
  page: number
  perPage: number
}

export const useGetDrugs = ({ query, language, page, perPage = 10 }: IUseGetDrugsProps) => {
  const drugsQuery = useQuery(DRUGS, {
    variables: {
      query,
      language,
      page,
      perPage
    },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: drugsQuery?.error })

  return drugsQuery
}
