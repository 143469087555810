import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const ADD_INSURANCE = gql`
  mutation addInsurance($name: String!, $fields: String!, $oAuthId: String!, $priority: String!) {
    addInsurance(name: $name, fields: $fields, oAuthId: $oAuthId, priority: $priority) {
      insuranceId
    }
  }
`

export const useAddInsurance = () => {
  const t = useTranslation()
  const [addInsurance, addInsuranceQuery] = useMutation(ADD_INSURANCE)
  useErrorToast({ error: addInsuranceQuery?.error })
  useSuccessToast({
    success: !!addInsuranceQuery?.data,
    message: t('common.insuranceAddedSuccessfully')
  })
  return { addInsurance, addInsuranceQuery }
}
