import { useToken } from '@/hooks/useToken'
import { useApollo } from '@/utils/apollo'
import { ApolloProvider } from '@apollo/client'
import { noop } from 'lodash'
import React, { createContext, useState } from 'react'

export interface IGraphQLContext {
  changePharmacyId: (pharmacyId: number) => void
  pharmacyId: number
}

export const GraphQLContext = createContext<IGraphQLContext>({
  changePharmacyId: noop,
  pharmacyId: 0
})

export const GraphQLProvider = ({ children, ...props }: any) => {
  const [pharmacyId, setPharmacyId] = useState<number>(0)
  const [token, setToken] = useState<string>(props.token)
  const { getToken } = useToken()
  const apolloClient = useApollo(
    props.pageProps.initialApolloState,
    token,
    pharmacyId
  )

  return (
    <GraphQLContext.Provider
      value={{
        changePharmacyId: async (pharmacyId: number) => {
          setPharmacyId(pharmacyId)
          setToken(await getToken())
        },
        pharmacyId
      }}
    >
      <ApolloProvider client={apolloClient}>
        {children}
      </ApolloProvider>
    </GraphQLContext.Provider>
  )
}
