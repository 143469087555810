import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from '../useErrorToast'

const USER_PHARMACIES = gql`
  query GetPharmacyUserPharmacies($oAuthId: String!) {
    getPharmacyUserPharmacies(oAuthId: $oAuthId) {
      pharmacyName
      pharmacyId
      role
    }
  }
`

export const useGetPharmacyUserPharmacies = ({ oAuthId }: { oAuthId: string }) => {
  const pharmacyUserPharmaciesQuery = useQuery(USER_PHARMACIES, {
    variables: { oAuthId },
    fetchPolicy: 'network-only'
  })

  useErrorToast({ error: pharmacyUserPharmaciesQuery?.error })

  return pharmacyUserPharmaciesQuery
}
