import { gql, useQuery } from '@apollo/client'

const GENCODE = gql`
  query getGenericInfo($GENcode: String!) {
    getGenericInfo(GENcode: $GENcode) {
      genericNameEn
      genericNameFr
    }
  }
`

export interface IUseGetGENCodeNameProps {
  GENcode: string
}

export const useGetGENCodeName = ({ GENcode }: IUseGetGENCodeNameProps) =>
  useQuery(GENCODE, {
    variables: {
      GENcode
    },
    fetchPolicy: 'network-only'
  })
