import { gql, useQuery } from '@apollo/client'
import { useErrorToast } from './useErrorToast'

const USERS = gql`
  query Users($isAdmin: Boolean, $query: String) {
    users(isAdmin: $isAdmin, query: $query) {
      oAuthId
      firstName
      lastName
    }
  }
`

export interface IUseGetUsersProps {
  isAdmin?: boolean
  query?: string
}

export const useGetUsers = (variables: IUseGetUsersProps, enabled: boolean = true) => {
  const usersQuery = useQuery(USERS, {
    variables,
    fetchPolicy: 'network-only',
    skip: !enabled
  })

  useErrorToast({ error: usersQuery?.error })

  return usersQuery
}
