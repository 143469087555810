import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast, useSuccessToast } from '.'

const RENEW_RX = gql`
  mutation renewRx($prescriptionId: Int!, $refillQuantity: Float!, $oAuthId: String!) {
    renewRx(prescriptionId: $prescriptionId, refillQuantity: $refillQuantity, oAuthId: $oAuthId)
  }
`

export const useRenewRx = () => {
  const t = useTranslation()
  const [renew, renewQuery] = useMutation(RENEW_RX)
  useErrorToast({ error: renewQuery?.error })
  useSuccessToast({
    success: !!renewQuery?.data,
    message: t('common.rxRenewedSuccessfully')
  })
  return { renew, renewQuery }
}
