import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const EDIT_PRESET_MESSAGE = gql`
  mutation EditPresetMessage($editPresetMessageId: Int!, $titleEn: String, $messageEn: String, $titleFr: String, $messageFr: String) {
    editPresetMessage(id: $editPresetMessageId, titleEn: $titleEn, messageEn: $messageEn, titleFr: $titleFr, messageFr: $messageFr) {
      id
      messageEn
      messageFr
    }
  }
`

export const useEditPresetMessage = () => {
  const t = useTranslation()
  const [editPresetMessage, editPresetMessageQuery] = useMutation(EDIT_PRESET_MESSAGE)
  useErrorToast({ error: editPresetMessageQuery?.error })
  useSuccessToast({
    success: !!editPresetMessageQuery?.data,
    message: t('predefinedMessage.updatedSuccessfully')
  })
  return { editPresetMessage, editPresetMessageQuery }
}
