import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@/utils/i18n'
import { useErrorToast } from '../useErrorToast'
import { useSuccessToast } from '../useSuccessToast'

const ADD_PHARMACY = gql`
  mutation addPharmacy($addPharmacyInput: AddPharmacyInput!) {
    addPharmacy(addPharmacyInput: $addPharmacyInput) {
      pharmacyId
      name
      address
      city
      province
      postalCode
      isActive
      phone
      email
      website
      bannerName
      bannerLogo
      businessHours
      empegoCode
    }
  }
`

export const useAddPharmacy = () => {
  const t = useTranslation()
  const [addPharmacy, addPharmacyQuery] = useMutation(ADD_PHARMACY)
  useErrorToast({ error: addPharmacyQuery?.error })
  useSuccessToast({
    success: !!addPharmacyQuery?.data,
    message: t('common.clientAddedSuccessfully')
  })
  return { addPharmacy, addPharmacyQuery }
}
