import { gql, useQuery } from '@apollo/client'

const MESSAGES = gql`
  query($oAuthId: String, $mostRecent: Int) {
    messages(mostRecent: $mostRecent, oAuthId: $oAuthId) {
      messageId
      oAuthId
      messageText
      adminOAuthId
      isAdmin
      img
      isMessageRead
      imgType
      createdAt
      senderFirstName
      senderLastName
    }
  }
`
export interface IUseGetMessagesProps {
  oAuthId: string
  mostRecent: number
}

export const useGetMessages = ({ oAuthId, mostRecent }: IUseGetMessagesProps) =>
  useQuery(MESSAGES, {
    variables: {
      oAuthId,
      mostRecent
    },
    pollInterval: 5_000,
    fetchPolicy: 'network-only'
  })
