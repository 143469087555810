import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'
import { useTranslation } from '../utils'

const CREATE_TASK = gql`
  mutation CreateTask(
    $title: String!
    $oAuthId: String!
    $type: Int!
    $payload: JSON!
    $priority: TaskPriority!
    $description: String
    $assignedTo: JSON!
    $attachedFileIds: JSON
    $dueDate: String!
    $status: TaskStatus
  ) {
    createTask(
      title: $title
      oAuthId: $oAuthId
      type: $type
      payload: $payload
      priority: $priority
      description: $description
      assignedTo: $assignedTo
      attachedFileIds: $attachedFileIds
      dueDate: $dueDate
      status: $status
    ) {
      taskId
      oAuthId
      typeOfTask
    }
  }
`

export const useCreateTask = () => {
  const [createTask, createTaskQuery] = useMutation(CREATE_TASK)
  const t = useTranslation()

  useErrorToast({ error: createTaskQuery?.error })
  useSuccessToast({
    success: !!createTaskQuery?.data,
    message: t('common.taskCreatedSuccessfully')
  })

  return {
    createTask,
    createTaskQuery
  }
}
