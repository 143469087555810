import { createContext } from 'react'
import { IUserProps } from '@/utils/types'
import { noop } from 'lodash'

export interface IMeContext {
  me: IUserProps
  refetchMe: () => void
  userLoading: boolean
}
export const MeContext = createContext<IMeContext>({
  me: null,
  userLoading: false,
  refetchMe: noop
})
