import { useTranslation } from '@/utils/i18n'
import { gql, useMutation } from '@apollo/client'
import { useErrorToast } from './useErrorToast'
import { useSuccessToast } from './useSuccessToast'

const EDIT_INSURANCE = gql`
  mutation updateInsurance($insuranceId: Int!, $priority: String, $name: String, $fields: String, $verified: Boolean) {
    updateInsurance(insuranceId: $insuranceId, priority: $priority, name: $name, fields: $fields, verified: $verified) {
      insuranceId
    }
  }
`

export const useEditInsurance = () => {
  const t = useTranslation()
  const [editInsurance, editInsuranceQuery] = useMutation(EDIT_INSURANCE)

  useErrorToast({ error: editInsuranceQuery?.error })
  useSuccessToast({
    success: !!editInsuranceQuery?.data,
    message: t('common.insuranceUpdatedSuccessfully')
  })
  return {
    editInsurance,
    editInsuranceQuery
  }
}
